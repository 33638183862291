import { useRef, useState } from "react";
import useWindowSize from "src/hooks/useWindowSize";
import { HashLink as Link } from "react-router-hash-link";
import Button from "./Button";
import Container from "./Container";
import Logo from "./Logo";
import { Fade } from "react-awesome-reveal";

import useAuth from "src/hooks/useAuth";
import { connectorLocalStorageKey, ConnectorNames } from "src/utils/connectors";
import { useWeb3React } from "@web3-react/core";
import { shorter } from "src/utils";

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const { width } = useWindowSize();
  const toggleMenu = () => setMenu(!menu);
  // const closeMenu = () => setMenu(false);

  const refMenu = useRef<any>(null);

  return (
    <div className="ld-navbar">
      <Container className="navbar-container">
        <Logo />
        <div className="case-one">
          <ContentSidebar toggleMenu={toggleMenu} />
        </div>
        <div className="case-two">
          <img
            onClick={toggleMenu}
            src="assets/landing/icons/menu.svg"
            alt="menu"
            width={30}
            height={30}
          />
        </div>
        {menu && width < 640 && (
          <Fade duration={300} delay={100} className="fade">
            <div ref={refMenu}>
              <ContentSidebar toggleMenu={toggleMenu} />
            </div>
          </Fade>
        )}
      </Container>
    </div>
  );
};

const ContentSidebar = ({ toggleMenu }: any) => {
  const { loginWallet } = useAuth();
  const { account } = useWeb3React();

  function connectWallet() {
    loginWallet(ConnectorNames.Injected);
    window.localStorage.setItem(
      connectorLocalStorageKey,
      ConnectorNames.Injected
    );
  }

  function goArtApp() {
    window.open("https://nft.difines.io/art");
  }

  return (
    <div className="sidebar">
      <Link to="/#explain" smooth onClick={toggleMenu} className="">
        About
      </Link>
      <Link to="/#collection" smooth className="" onClick={toggleMenu}>
        Collection
      </Link>
      <Link to="/#team" smooth onClick={toggleMenu} className="">
        Mint
      </Link>
      <Button variant="primary" onClick={goArtApp} className="art-app-btn">
        Art Creator
      </Button>
      <Button variant="primary" onClick={connectWallet}>
        {account ? shorter(account) : "Connect Wallet"}
      </Button>
    </div>
  );
};

export default Navbar;
