import React from "react";
import { HashLink as Link } from "react-router-hash-link";

interface ButtonProps {
  className?: string;
  variant?: string;
  isLink?: boolean;
  href?: string;
  submit?: boolean;
  onClick?: any;
  children?: React.ReactNode;
}

const Button = ({
  className = "",
  variant,
  isLink = false,
  href,
  submit = false,
  onClick,
  children,
}: ButtonProps) => {
  let rootClass = "button-link ";
  if (variant === "primary") {
    rootClass += "btn_primary";
  } else if (variant === "outlinePrimary") {
    rootClass += "btn_primary_outline";
  } else {
    rootClass += "bg-[#ffffff1A]";
  }
  if (isLink) {
    return (
      <Link
        to={href ? href : "#"}
        smooth
        className={rootClass + " " + className}
        onClick={onClick && onClick}
      >
        {children}
      </Link>
    );
  }
  return (
    <button
      onClick={onClick}
      type={submit ? "submit" : "button"}
      className={rootClass + " " + className}
    >
      {children}
    </button>
  );
};

export default Button;
