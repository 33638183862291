import React from "react";
import Navbar from "./components/LandingPage/Navbar";
import Hero from "./components/LandingPage/Hero";
import Sponsor from "./components/LandingPage/Sponsor";
import AboutUs from "./components/LandingPage/AboutUs";
import Collections from "./components/LandingPage/Collections";
import Footer from "./components/LandingPage/Footer";
import GetReady from "./components/LandingPage/GetReady";

import { useEagerConnect } from "src/hooks/useEagerConnect";

import "./styles/landing.scss";

const Landing = () => {
  useEagerConnect();

  return (
    <div className="landing" id="top">
      <Navbar />
      <Hero />
      <Sponsor />
      <AboutUs />
      <Collections />
      <GetReady />
      <Footer />
    </div>
  );
};

export default Landing;
