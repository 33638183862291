import { useState, useEffect } from "react";
import Button from "./Button";
import Container from "./Container";
import { JackInTheBox, Reveal } from "react-awesome-reveal";
import { fadeInDownShorter, fadeInLeft, fadeInUp } from "../../utils/keyframes";
import MintNFT from "./MintNFT";

const Hero = () => {
  const [isShow, setIsShow] = useState<boolean>(false);

  const goArtCreator = () => {
    window.open("https://nft.difines.io/art");
  };

  const mintNFT = () => {
    setIsShow(true);
  };
  const closeModal = () => {
    setIsShow(false);
  };

  return (
    <>
      <Container className="ld-hero">
        <div className="left-side">
          <Reveal keyframes={fadeInLeft} duration={800} delay={200} triggerOnce>
            <h1 className="">
              Create Art & Mint Your Creative NFT Only 30 Seconds
            </h1>
          </Reveal>
          <Reveal
            keyframes={fadeInDownShorter}
            duration={1000}
            delay={800}
            triggerOnce
          >
            <p className="">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit.
            </p>
          </Reveal>

          <div className="button-group">
            <Reveal
              keyframes={fadeInUp}
              duration={1000}
              delay={200}
              triggerOnce
            >
              <Button
                isLink
                onClick={goArtCreator}
                className="button-one"
                variant="primary"
              >
                Art Creator
              </Button>
            </Reveal>
            <Reveal
              keyframes={fadeInUp}
              duration={1000}
              delay={400}
              triggerOnce
            >
              <Button isLink onClick={mintNFT}>
                Mint NFT
              </Button>
            </Reveal>
          </div>
          <div className="statistic ">
            <Reveal
              keyframes={fadeInLeft}
              duration={500}
              delay={200}
              triggerOnce
            >
              <div>
                <p className="top">37k+</p>
                <p className="down">Artworks</p>
              </div>
            </Reveal>
            <Reveal
              keyframes={fadeInLeft}
              duration={500}
              delay={400}
              triggerOnce
            >
              <div>
                <p className="top">20k+</p>
                <p className="down">Artists</p>
              </div>
            </Reveal>
            <Reveal
              keyframes={fadeInLeft}
              duration={500}
              delay={600}
              triggerOnce
            >
              <div>
                <p className="top">99k+</p>
                <p className="down">Auctions</p>
              </div>
            </Reveal>
          </div>
        </div>
        <div className="right-side">
          <JackInTheBox delay={200} triggerOnce>
            <div className="outdiv">
              <img src="assets/landing/hero/heroimage.png" alt="hero" />
            </div>
          </JackInTheBox>
        </div>
      </Container>
      <MintNFT isShow={isShow} onClose={closeModal} />
    </>
  );
};

export default Hero;
