import React from "react";
import Container from "./Container";
import Button from "./Button";
import { Reveal } from "react-awesome-reveal";
import { fadeInDownShorter } from "../../utils/keyframes";

const GetReady = () => {
  function mintNFT() {
    alert("mint your nft");
  }

  return (
    <Container id="team" className="">
      <div className="ld-getready">
        <h2 className="">Get ready to mint your NFT</h2>
        <Reveal delay={200} duration={1000} keyframes={fadeInDownShorter} triggerOnce>
          <Button onClick={mintNFT}>Get Started</Button>
        </Reveal>
      </div>
    </Container>
  );
};

export default GetReady;
