import React from "react";
import Container from "./Container";
import { Reveal } from "react-awesome-reveal";
import { fadeInLeft } from "../../utils/keyframes";

const AboutUs = () => {
  return (
    <Container className="ld-about-us" id="explain">
      <div className="heading">
        <h2 className="">About Collection</h2>
      </div>
      <div className="content">
        <div className="left-side">
          <Reveal keyframes={fadeInLeft} triggerOnce>
            <div className="out-image">
              <img src="assets/landing/aboutus.png" alt="about us" />
            </div>
          </Reveal>
        </div>
        <div className="right-side">
          <h3 className="">Get Popular NFT</h3>
          <p className="">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
            nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
            tellus elit sed risus. Maecenas eget condimentum velit, sit amet
            feugiat lectus. Class aptent taciti sociosqu ad litora torquent per
            conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
            enim egestas, ac scelerisque ante pulvinar.{" "}
          </p>
          <p className="">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
            nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
            tellus elit sed risus. Maecenas eget condimentum velit, sit amet
            feugiat lectus. Class aptent taciti sociosqu ad litora torquent per
            conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
            enim egestas, ac scelerisque ante pulvinar.{" "}
          </p>
          <p className="">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
            nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
            tellus elit sed risus. Maecenas eget condimentum velit, sit amet
            feugiat lectus. Class aptent taciti sociosqu ad litora torquent per
            conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
            enim egestas, ac scelerisque ante pulvinar.{" "}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default AboutUs;
