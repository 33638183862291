import Art from "./Art";
import Landing from "./Landing";
import { Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />}></Route>
      <Route path="art" element={<Art />}></Route>
    </Routes>
  );
};

export default App;
