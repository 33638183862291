import React from "react";
import Container from "./Container";
import { Reveal } from "react-awesome-reveal";
import { fadeInUp } from "../../utils/keyframes";
const Sponsor = () => {
  return (
    <Container className="ld-sponsor">
      <Reveal keyframes={fadeInUp} duration={800} delay={200} triggerOnce>
        <div className="out-image">
          <img src="assets/landing/sponsor/binance.svg" alt="binance" />
        </div>
      </Reveal>
      <Reveal keyframes={fadeInUp} duration={800} delay={400} triggerOnce>
        <div className="out-image">
          <img src="assets/landing/sponsor/ethereum.svg" alt="ethereum" />
        </div>
      </Reveal>
      <Reveal keyframes={fadeInUp} duration={800} delay={600} triggerOnce>
        <div className="out-image">
          <img
            src="assets/landing/sponsor/blockchainio.svg"
            alt="blockchainio"
          />
        </div>
      </Reveal>
    </Container>
  );
};

export default Sponsor;
