import { list_artwork, list_collection } from "../../utils/fake_data";
import React, { useState } from "react";
import Button from "./Button";
import Container from "./Container";
import NFTItem from "./NFTItem";
import { Reveal } from "react-awesome-reveal";
import {
  fadeInDownShorter,
  fadeInDownShorter2,
  fadeInLeft,
} from "../../utils/keyframes";

const Collections = () => {
  const [listBtn, setListBtn] = useState([
    {
      name: "Art",
      status: true,
      delay: 100,
    },
    {
      name: "Sport",
      status: false,
      delay: 400,
    },
    {
      name: "Photography",
      status: false,
      delay: 700,
    },
    {
      name: "Pattern",
      status: false,
      delay: 1000,
    },
  ]);

  const filterContent: any = {
    Art: list_artwork,
    Sport: list_collection,
    Photography: list_collection,
    Pattern: list_collection,
  };

  const [filterName, setFileterContent] = useState<any>("Art");

  const handleClickMenu = (name: any) => {
    const filterBtn = listBtn.map((item) => {
      return {
        ...item,
        status: item.name === name ? true : false,
      };
    });
    setListBtn(filterBtn);
    setFileterContent(name);
  };

  return (
    <Container id="collection" className="ld-collections">
      <div className="heading ">
        <h2 className="">Our NFT Collection</h2>
      </div>
      <div className="button-group ">
        {listBtn.map((item, i) => (
          <Reveal
            key={i}
            keyframes={fadeInLeft}
            duration={300}
            delay={item.delay}
            triggerOnce
          >
            <Button
              onClick={() => handleClickMenu(item.name)}
              variant={item.status ? "primary" : ""}
              className="select-btn"
            >
              {item.name}
            </Button>
          </Reveal>
        ))}
      </div>

      <div className="item-group">
        {filterContent[filterName].map((item: any, i: any) => (
          <Reveal
            key={i}
            keyframes={fadeInDownShorter}
            duration={500}
            delay={50 * (i + 1)}
            triggerOnce
          >
            <NFTItem data={item} />
          </Reveal>
        ))}
      </div>
    </Container>
  );
};

export default Collections;
