import React from "react";

const Logo = () => {
  return (
    <div className="logo">
      <p>Difines </p>
      <p className="sec">NFT</p>
    </div>
  );
};

export default Logo;
