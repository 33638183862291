import React from "react";
import Button from "./Button";

const NFTItem = ({ data }: any) => {
  const { image, username, price, product_name } = data;
  return (
    <div className="nft-item">
      <div className="out-image">
        <img src={image} alt={username} />
      </div>
      <div className="info">
        <p>{product_name}</p>
        <p>{price} ETH</p>
      </div>
      <Button className="buy-now" variant="primary">
        Buy Now
      </Button>
    </div>
  );
};

export default NFTItem;
