import React from "react";

const Container = ({ className, children, ...rest }: any) => {
  return (
    <div
      {...rest}
      className={`container ${className}`}
    >
      {children}
    </div>
  );
};

export default Container;
