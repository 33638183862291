import React from "react";
import Menu from "./components/Menu";
import Header from "./components/Header/Header";
import Canvas from "./components/Canvas";
import Toolbar from "./components/Toolbar/Toolbar";

import "./styles/main.scss";

const Art = () => {
  return (
    <div className="app">
      <Header />
      <Menu />
      <Toolbar />
      <Canvas />
    </div>
  );
};

export default Art;
