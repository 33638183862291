import React from "react";
import Container from "./Container";

const Footer = () => {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <Container className="ld-footer">
      <div className="top">
        &copy; <span className="one">Sunshine </span>
        Owned By <span className="two">Sato</span>
      </div>
      <div onClick={scrollToTop} className="scroll-btn">
        UP
      </div>
    </Container>
  );
};

export default Footer;
