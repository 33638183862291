export const list_collection = [
  {
    id: 1,
    username: "isarjant0",
    price: 1.646567,
    image: "assets/landing/collections/collection1.png",
    product_name: "Milvago chimachima",
  },
  {
    id: 2,
    username: "rwherrett1",
    price: 3.457135,
    image: "assets/landing/collections/collection2.png",
    product_name: "Castor fiber",
  },
  {
    id: 3,
    username: "mbasnall2",
    price: 2.34111,
    image: "assets/landing/collections/collection3.png",
    product_name: "Ara ararauna",
  },
  {
    id: 4,
    username: "awicher3",
    price: 2.610668,
    image: "assets/landing/collections/collection4.png",
    product_name: "Meleagris gallopavo",
  },
  {
    id: 5,
    username: "tbeaney4",
    price: 2.130348,
    image: "assets/landing/collections/collection5.png",
    product_name: "Tachybaptus ruficollis",
  },
  {
    id: 6,
    username: "vsmittoune5",
    price: 3.053478,
    image: "assets/landing/collections/collection6.png",
    product_name: "Pycnonotus nigricans",
  },
  {
    id: 7,
    username: "vdeyes6",
    price: 2.636318,
    image: "assets/landing/collections/collection7.png",
    product_name: "Carduelis pinus",
  },
  {
    id: 8,
    username: "mdann7",
    price: 3.81189,
    image: "assets/landing/collections/collection8.png",
    product_name: "Bubalornis niger",
  },
  {
    id: 9,
    username: "ukiljan8",
    price: 4.304832,
    image: "assets/landing/collections/collection9.png",
    product_name: "Semnopithecus entellus",
  },
];

export const list_artwork = [
  {
    id: 1,
    username: "isarjant0",
    price: 1.646567,
    image: "assets/landing/collections/collection1.png",
    product_name: "Milvago chimachima",
    delay: 300,
  },
  {
    id: 2,
    username: "rwherrett1",
    price: 3.457135,
    image: "assets/landing/collections/collection2.png",
    product_name: "Castor fiber",
    delay: 200,
  },
  {
    id: 3,
    username: "mbasnall2",
    price: 2.34111,
    image: "assets/landing/collections/collection3.png",
    product_name: "Ara ararauna",
    delay: 100,
  },
];

export const list_top_creator = [
  {
    id: 1,
    username: "isarjant0",
    price: 1.646567,
    image: "/creators/creator1.png",
    bgimage: "/creators/bgcreator1.png",
    product_name: "Milvago chimachima",
  },
  {
    id: 2,
    username: "rwherrett1",
    price: 3.457135,
    image: "/creators/creator2.png",
    bgimage: "/creators/bgcreator2.png",
    product_name: "Castor fiber",
  },
  {
    id: 3,
    username: "mbasnall2",
    price: 2.34111,
    image: "/creators/creator3.png",
    bgimage: "/creators/bgcreator3.png",
    product_name: "Ara ararauna",
  },
];

export const list_FAQ = [
  {
    id: 1,
    title: "Galium odoratum (L.) Scop.",
    desc: "Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for closed fracture with delayed healing",
  },
  {
    id: 2,
    title: "Asclepias perennis Walter",
    desc: "Malignant neoplasm of unspecified part of adrenal gland",
  },
  {
    id: 3,
    title: "Packera cynthioides (Greene) W.A. Weber & Á. Löve",
    desc: "Displaced fracture of unspecified tibial spine, initial encounter for closed fracture",
  },
  {
    id: 4,
    title: "Hedyotis degeneri Fosberg var. coprosmifolia Fosberg",
    desc: "23 weeks gestation of pregnancy",
  },
  {
    id: 5,
    title: "Limnanthes douglasii R. Br.",
    desc: "Other dislocation of unspecified shoulder joint",
  },
  {
    id: 6,
    title: "Ilex obcordata Sw. var. obcordata",
    desc: "Personal history of other (corrected) congenital malformations",
  },
];
