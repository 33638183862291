export const Networks = {
    MainNet: 56,
    Testnet: 97,
  };
  
  export const ALL_SUPPORTED_CHAIN_IDS: number[] = [
    Networks.MainNet,
    Networks.Testnet,
  ];
  
  export const currentNetwork: number =
    parseInt(process.env.REACT_APP_NETWORK_ID || "") || 56;
  
  export const shorter = (str: string) =>
    str?.length > 8 ? str.slice(0, 6) + "..." + str.slice(-4) : str;
  