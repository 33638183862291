import { MouseEventHandler, useState, useEffect, useRef } from "react";
import Loader from "./Loader";
import Button from "./Button";
import { useForm } from "react-hook-form";
import { create as ipfsHttpClient, IPFSHTTPClient } from "ipfs-http-client";
import { useWeb3React } from "@web3-react/core";
import Web3Modal from "web3modal";
import windowSize from "src/hooks/useWindowSize";
import { ethers } from "ethers";
import { shorter } from "src/utils";
import toast from "react-hot-toast";
import standardTestNFTABI from "src/utils/abis/standardTestDifinesNFT.json";
import standardMainNFTABI from "src/utils/abis/standardMainDifinesNFT.json";

let standardNFTABI = standardTestNFTABI.abi;
let standardNFTAddress = "0xEc821F85f9DE79DceD60f7DB6b8CC1C07D89E587";

interface ParentProps {
  isShow: Boolean;
  onClose: MouseEventHandler<HTMLDivElement>;
}
interface TokenInfoProps {
  tokenName: string;
  tokenDesc: string;
}
interface ImageUrlProps {
  realUrl: string;
  virtualUrl: string;
}

let client: IPFSHTTPClient | undefined;
client = ipfsHttpClient({ url: "https://ipfs.infura.io:5001/api/v0" });

const MintNFT = ({ isShow, onClose }: ParentProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mintStatus, setMintStatus] = useState<string>("none");
  const [txResult, setTxResult] = useState<string>("");
  const fileRef = useRef<any>(null);
  const [imgUrl, setImgUrl] = useState<ImageUrlProps>({
    realUrl: "",
    virtualUrl: "",
  });
  const Network: any = {
    1: { name: "Ethereum Mainnet", url: "https://etherscan.io/" },
    3: { name: "Ropsten Testnet", url: "https://etherscan.io/" },
    4: { name: "Rinkeby Testnet", url: "https://etherscan.io/" },
    5: { name: "Goerli Testnet", url: "https://etherscan.io/" },
    56: { name: "BSC Mainnet", url: "https://bscscan.com/tx/" },
    97: { name: "BSC Testnet", url: "https://testnet.bscscan.com/tx/" },
  };
  const AbiInfo: any = {
    56: {
      abi: standardMainNFTABI.abi,
    },
    97: {
      abi: standardTestNFTABI.abi,
    },
  };
  const NFTAddress: any = {
    56: "0x50B1e40AeB69772abaccA59486C2257a62B6F9ED",
    97: "0xEc821F85f9DE79DceD60f7DB6b8CC1C07D89E587",
  };
  const initialState: TokenInfoProps = {
    tokenName: "",
    tokenDesc: "",
  };
  const [tokenInfo, setTokenInfo] = useState<TokenInfoProps>({
    ...initialState,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TokenInfoProps>();
  const { account, chainId } = useWeb3React();
  const { width } = windowSize();

  useEffect(() => {
    if (chainId) {
      standardNFTABI = AbiInfo[chainId]["abi"];
      standardNFTAddress = NFTAddress[chainId];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account]);

  function triggerPreviewImage() {
    fileRef.current?.click();
  }
  function previewImage(evt: any) {
    evt.persist();
    const file = evt.target.files[0];
    if (file)
      setImgUrl({
        realUrl: file,
        virtualUrl: window.URL.createObjectURL(file),
      });
  }
  function onAssetChange(e: { target: { name: any; value: any } }) {
    const { name, value } = e.target;
    setTokenInfo({
      ...tokenInfo,
      [name]: value,
    });
  }
  async function onSubmit(data: TokenInfoProps) {
    setMintStatus("start");
    if (!account) {
      toast.error("Connect your metamask to mint");
      return;
    }
    if (!imgUrl.realUrl) {
      return;
    }
    setIsLoading(true);
    try {
      // Upload image to IPFS
      const imgResult = await (client as IPFSHTTPClient).add(imgUrl.realUrl, {
        progress: (prog) => console.log(`img received: ${prog}`),
      });
      const imgIpfsUrl = `https://ipfs.infura.io/ipfs/${imgResult.path}`;

      // Upload metadata to IPFS
      const metaData = JSON.stringify({
        name: data.tokenName,
        desc: data.tokenDesc,
        image: imgIpfsUrl,
      });
      const metaResult = await (client as IPFSHTTPClient).add(metaData, {
        progress: (prog) => console.log(`metadata received: ${prog}`),
      });
      const metaIpfsUrl = `https://ipfs.infura.io/ipfs/${metaResult.path}`;

      // Interact with smart contract (mint)
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner();
      let mintContract = new ethers.Contract(
        standardNFTAddress,
        standardNFTABI,
        signer
      );
      let mintTx = await mintContract.mintNFT(account, metaIpfsUrl);
      await mintTx.wait();
      setTxResult(mintTx.hash);
      setMintStatus("success");
    } catch (error) {
      console.error("Error while uploading file: ", error);
      setMintStatus("fail");
    } finally {
      setIsLoading(false);
    }
  }
  function onReset() {
    reset();
    setImgUrl({ realUrl: "", virtualUrl: "" });
  }

  return (
    <div className={`modal ${isShow ? "show-modal" : "hide-modal"}`}>
      <div className="modal-container">
        <div className="close" onClick={onClose}>
          &#10006;
        </div>
        <div
          className={`overlay ${isLoading ? "show-overlay" : "hide-overlay"}`}
        >
          <Loader />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="heading">Mint Your NFT</h1>
          {mintStatus === "success" && chainId ? (
            <div className="content">
              <div className="column">
                <h2 className="heading">Successfully minted!</h2>
                <a
                  href={Network[chainId]["url"] + txResult}
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here to check transaction
                </a>
              </div>
            </div>
          ) : (
            <>
              <div className="connection">
                <div className="row">
                  <label htmlFor="">Selected Account *</label>
                  <span>
                    {account
                      ? width < 640
                        ? shorter(account)
                        : account
                      : "Please connect your metamask"}
                  </span>
                </div>
                <div className="row">
                  <label htmlFor="">Current Network *</label>
                  <span>
                    {chainId
                      ? Network[chainId]["name"]
                      : "Please connect your metamask"}
                  </span>
                </div>
              </div>
              <div className="content">
                <div className="left">
                  <div className="preview" onClick={triggerPreviewImage}>
                    {imgUrl.virtualUrl ? (
                      <img src={imgUrl.virtualUrl} alt="preview-img" />
                    ) : (
                      <div className="column">
                        <div className="img-button">Upload Your Asset</div>
                        {mintStatus === "start" && (
                          <p className="invalid-feedback">
                            Assset Image is required.
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileRef}
                    onChange={previewImage}
                    hidden
                  />
                </div>
                <div className="right">
                  <div className="column">
                    <input
                      type="text"
                      placeholder="Asset Name"
                      className="input"
                      {...register("tokenName", { required: true })}
                      name="tokenName"
                      onChange={onAssetChange}
                    />
                    {errors.tokenName && (
                      <p className="invalid-feedback">
                        Assset Name is required.
                      </p>
                    )}
                  </div>
                  <div className="column">
                    <textarea
                      placeholder="Asset Description"
                      className="text-area"
                      {...register("tokenDesc", { required: true })}
                      name="tokenDesc"
                      onChange={onAssetChange}
                    />
                    {errors.tokenDesc && (
                      <p className="invalid-feedback">
                        Assset Description is required.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="footer">
                <Button variant="primary" submit>
                  Mint
                </Button>
                <Button variant="fill" onClick={onReset}>
                  Reset
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default MintNFT;
